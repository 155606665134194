@import "../../default/default.css";

div.resume-entry-container h3 {
    font-size: 24px;
    margin-top: 14px;
    margin-bottom: 6px;
    color: var(--color-6, #ebebeb);
}

div.resume-entry-container h3 span {
    padding-right: 8px;
}

div.resume-entry-container h3 span.highlight-company{
    color: var(--color-4, #ebebeb);
}

a.company-hyperlink {
    display: inline-block;
    text-decoration: none;
    color: var(--color-4, #ebebeb);
}

a.company-hyperlink:after { 
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    position: relative;
    background: var(--color-4, #7ccdf4);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  
  a.company-hyperlink:hover:after { 
    width: 100%; 
    left: 0; 
  }

div.resume-entry-container h4.dates {
    margin-top: 14px;
    letter-spacing: 0.05em;
    font-family: "SF Mono", Monaco, monospace;
    color: var(--color-7, #ebebeb);
}

div.resume-entry-container div ul {
    padding-top: 10px;
    padding-left: 0;
    margin: 0;
    list-style: none;
}

div.resume-entry-container div ul li {
    list-style-type: none;
    display: list-item;
    position: relative;
    padding-left: 20px;
    color: var(--color-7, #ebebeb);
    margin-bottom: 10px;
}

div.resume-entry-container div ul li::before { 
    box-sizing: inherit;
    content: "▹";
    position: absolute;
    left: 0;
    color: var(--color-4, #ebebeb);
}

@media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 812px) {


    span.highlight-company {
        display: block;
    }

    div.role-tabs-container div.resume-entry-div {
        float: left;
        padding-left: 5px;
    }
}

