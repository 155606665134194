@import "../../default/default.css";

div .role-tabs-container {
    display: inline-block;
    position: relative;
    max-width: 80%;
    margin-top: 20px;
}

div .role-tabs-container ul.role-tabs-ul {
    display: inherit;
    padding: 0px;
    margin: 0px;
    list-style: none;
    max-width: 120px;
}

div .role-tabs-container ul.role-tabs-ul button {
    text-decoration-skip-ink: auto;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    display: flex;
    width: 100%;
    background-color: transparent;
    line-height: 64px;
    height: 64px;
    text-align: left;
    white-space: nowrap;
    font-size: 18px;
    padding: 0px 20px 2px;
    border: none;
    transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

div .role-tabs-container ul.role-tabs-ul button[aria-selected="false"] {
    color: var(--color-7, #ebebeb);
    border-left: 2px solid var(--color-7, #ebebeb);
}

div .role-tabs-container ul.role-tabs-ul button[aria-selected="true"] {
    color: var(--color-5, #ebebeb);
    border-left: 2px solid var(--color-5, #ebebeb);
}

div .role-tabs-container ul.role-tabs-ul button:focus {
    outline: none;
    box-shadow: none;
}

div .role-tabs-container div.resume-entry-div:focus {
    outline: none;
    box-shadow: none;
}


div .role-tabs-container div.resume-entry-div {
    float: right;
    max-width: 600px;
    padding-top: 0px;
    padding-left: 30px;
}

@media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 812px) {

div .role-tabs-container {
    display: block;
}

div .role-tabs-container ul.role-tabs-ul {
    width: calc(100% + 70px);
    margin-left: -15px;
    display: flex;
    overflow: auto;
    max-width: 100vw;
    overflow-x: scroll;
    margin-bottom: 20px;
    list-style: none;
}

div .role-tabs-container ul.role-tabs-ul button[aria-selected="false"] {
    color: var(--color-7, #ebebeb);
    border: none;
    border-bottom: 2px solid var(--color-7, #ebebeb);
}

div .role-tabs-container ul.role-tabs-ul button[aria-selected="true"] {
    color: var(--color-5, #ebebeb);
    border: none;
    border-bottom: 2px solid var(--color-5, #ebebeb);
}

}
