@import "../../default/default.css";


div.contact-container {
    animation: fadeIn 2.5s ease-in 0s 1;
    width: 100vw;
}

div.social-icon-div {
    position: fixed;
    bottom: 50px;
    width: 48px;
    left: 40px;
    right: auto;
}



ul.social-icons {
    list-style: none;
    padding: 10px 0;
    align-items: center;
}

ul.social-icons li {
    padding-top: 20px;
}

ul.social-icons li a {
    display: inline-block;
    width: 48px;
    height: 48px;
    text-align: center;
    background: #262626;
    color: #fff;
    text-decoration: none;
    border-radius: 50%;
    transition: 0.5s;
    font-size: 24px;
}

ul.social-icons li a {
    line-height: 48px;
    margin: 0px 5px;
}

ul.social-icons li a:hover {
    background: var(--color-4, #00a9e2);
}

@media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 812px) {
        div.contact-container {
            position: relative;
            bottom: 20px;
            margin: 20px 10px;
            height: 50px;
            width: 100vw;
        }

        ul.social-icons {
            display: block;
            margin: 0 auto;
            justify-content: center;
        }

        ul.social-icons li {
            display: inline-block;
            /* float: left; */
        }

        div.contact-container {
            display: none;
        }
    }