@import "../../default/default.css";

div.feature-left {
    width: 90%;
    max-width: 1100px;
    padding-bottom: 20px;
    margin: auto;
    display: block;
}

div.sub-feature {
    display: flex;
    position: relative;
    align-items: center;
}

div.feature-img, div.feature-txt {
    display: inline-block;
    max-width: 50%;
}

div.feature-img img{
    max-width: 80%;
}

div.feature-txt {
    text-align: left;
}

div.feature-txt a {
    display: inline-block;
    width: 48px;
    height: 48px;
    text-align: center;
    background: #262626;
    color: #fff;
    text-decoration: none;
    border-radius: 50%;
    transition: 0.5s;
    font-size: 24px;
}

div.feature-txt a {
    line-height: 48px;
    margin: 0px 5px;
}

div.feature-txt a:hover {
    background: var(--color-4, #00a9e2);
}

div.feature-txt h3 {
    font-size: 13px;
    font-family: "SF Mono", Monaco, monospace;
    margin-top: 10px;
    color: var(--color-4, #1b1b1b);
}

div.feature-txt h2 {
    font-weight: 700;
    font-size: 32px;
    margin-top: 10px;
    color: var(--color-5, #1b1b1b);
}

div.feature-p {
    box-shadow: rgba(2, 12, 27, 0.7) 0px 10px 30px -15px;
    position: relative;
    z-index: 2;
    background-color: rgb(23, 42, 69);
    color: rgb(168, 178, 209);
    font-size: 18px;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    padding: 25px;
    border-radius: 3px;
}

div.feature-p p {
    color: var(--color-6, #1b1b1b);
    z-index: 2;
    font-size: 18px;
}

@media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 812px) {
        div.sub-feature {
            position: relative;
            align-items: center;
        }
        
        div.feature-img, div.feature-txt {
            position: relative;
            max-width: 90%;
        }
        
        div.feature-img img{
            position: absolute;
            max-width: 100%;
            opacity: 0.8;
        }

    }