@import '../../default/default.css';

div.logo-container img {
    height: 60px;
    float: left;
}

@media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 812px) {
        div.logo-container img {
            height: 50px;
        }

    }
