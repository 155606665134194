@import "../../default/default.css";

section {
    animation: fadeInFromBottom 2s ease-out 0s 1;
}

section.welcome-container {
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100vh;
    margin: 100px auto;
}

#welcome h3 {
    margin-left: 6px;
    margin-block-start: 0;
    margin-block-end: 0;
    color: var(--color-5, #ebebeb);
    font-size: max(1.2vw, 16px);
}

#welcome h2 {
    margin-block-start: 0;
    margin-block-end: 0;
    color: var(--color-8, #c5c6c6);
    font-size: min(4.8vw, 80px);
    opacity: 0.8;
}

#welcome h1 {
    margin-block-start: 0;
    margin-block-end: 0;
    color: var(--color-10, #7ccdf4);
    font-size: min(10vw, 100px);
    opacity: 0.8;
}

#welcome p {
    margin-left: 6px;
    max-width: 600px;
    padding-top: 40px;
    color: var(--color-8, #ebebeb);
    font-size: 24px;
}

@media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 812px) {

        #welcome h3 {
            font-size: min(5vw, 14px);
        }
        
        #welcome h2 {
            font-size: min(12vw, 28px);
            margin-left: 3px;
        }
        
        #welcome h1 {
            font-size: min(20vw, 48px);
            margin-left: 3px;
        }

        #welcome p {
            font-size: min(5vw, 16px);
        }

        section.welcome-container {
            min-height: 200px;
            max-height: 400px;
        }


    }