@import "../../default/default.css";

section.experience-container {
    margin: auto;
    width: auto;
}

div .experience-content {
    width: 100%;
    margin: 20px auto;
}

div .experience-content h1 {
    font-size: 50px;
    color: var(--color-5, #7ccdf4);
}

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 812px) {

    div .experience-content {
        margin-top: 400px;
    }

    div .experience-content h1 {
        font-size: 34px;
      }

}