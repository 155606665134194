@import "../../default/default.css";

div .about-container {
  margin-top: 200px;
  max-width: 1000px;
  min-height: 100vh;
}

div .about-container div.about-text {
  display: inline-block;
  max-width: 52%;
}

div .about-container h1 {
  font-size: 50px;
  color: var(--color-5, #7ccdf4);
}

div .about-container p {
  font-size: 20px;
  color: var(--color-8, #ebebeb);
}

div .about-container p a.custom-link {
    text-decoration: none;
    color: var(--color-5, #7ccdf4);
    display: inline-block;
    position: relative;
}

div .about-container p a.custom-link:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    position: absolute;
    background: var(--color-5, #7ccdf4);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }

  div .about-container p a.custom-link:hover:after { 
    width: 100%; 
    left: 0; 
  }

  div.about-container div.cliffs-photo {
    float: right;
  }

  div.about-container div.cliffs-photo img {
    margin-top: 50px;
    max-width: 400px;
    max-height: 450px;
    object-fit: cover;
  }

  @media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 812px) {
      div.about-container {
        max-width: 900%;
        display: block;
      }

      div.about-container div.about-text {
        display: block;
        max-width: 90%;
      }

      div.about-container div.cliffs-photo {
        left: -15%;
        position: relative;
        display: block;
        max-width: 80%;
        margin: auto;
      }

      div.cliffs-photo img {
        max-width: 75%;
        max-height: 200px;
        margin: 0;
      }

      div .about-container h1 {
        font-size: 34px;
      }
    }