@import "normalize.css";
@import "blue.css";
/* @import "red.css"; */
/* @import "green.css"; */

@keyframes fadeInFromTop {
    0%, 50% {
        opacity: 0;
        scale: 0.5;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        scale: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInFromBottom {
    0%, 50% {
        opacity: 0;
        scale: 0.5;
        transform: translateY(20%);
    }
    100% {
        opacity: 1;
        scale: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    0%, 50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes loader {
    0% { transform: rotate(0deg);}
    25% { transform: rotate(180deg);}
    50% { transform: rotate(180deg);}
    75% { transform: rotate(360deg);}
    100% { transform: rotate(360deg);}
  }