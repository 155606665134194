div #nav-div {
    padding: 0 50px;
}

div #content {
    padding: 120px 100px;
    max-width: 1200px;
    margin: auto;
}

@media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 812px) {

    div #content {
        padding: 40px 20px;
        max-width: 700px;
        margin: auto;
    }

    div #nav-div {
        padding: 0 15px;
    }

    html, body {
        overflow-x: hidden;
        overflow-y: scroll;
    }
        

    }