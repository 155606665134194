@import "../../default/default.css";

div.toggle-center {
    position: relative;
    float: right;
    transform: translate(-50%, -50%);
    margin: 35px 80px;
}

input[type="checkbox"] {
    position: relative;
    width: 120px;
    height: 40px;
    -webkit-appearance: none;
    background: linear-gradient(0deg, #333, #000);
    outline: none;
    border-radius: 20px;
    box-shadow: 0 0 0 4px #353535, 0 0 0 5px #3e3e3e, 
        inset 0 0 10px rgba(0, 0, 0, 1), 
        0 5px 20px rgba(0, 0, 0, 0.5), 
        inset 0 0 15px rgba(0, 0, 0, .2);
}
input:checked[type="checkbox"] {
    background: linear-gradient(0deg, #6dd1ff, #20b7ff);
    transition: 0.5s;
    box-shadow: 0 0 2px #6dd1ff, 0 0 4px #353535, 0 0 0 5px #3e3e3e, 
        inset 0 0 10px rgba(0, 0, 0, 1), 
        0 5px 20px rgba(0, 0, 0, 0.5), 
        inset 0 0 15px rgba(0, 0, 0, .2);
}

input[type="checkbox"]:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 40px;
    background: linear-gradient(0deg, #000, #6b6b6b);
    border-radius: 20px;
    box-shadow: 0 0 0 1px #232323;
    transform: scale(.98, .96);
    transition: 0.5s ease-in-out;
}

input:checked[type="checkbox"]:before {
    left: 40px;
}

input[type="checkbox"]:after {
    content: '';
    position: absolute;
    top: calc(50% - 2px);
    left: 65px;
    width: 4px;
    height: 4px;
    background: linear-gradient(0deg, #6b6b6b, #000);
    border-radius: 50%;
    transition: 0.5s ease-in-out;
}

input:checked[type="checkbox"]:after {
    background: #63cdff;
    left: 105px;
    box-shadow: 0 0 5px #13b3ff, 0 0 5px #13b3ff;
}

@media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 812px) {
        div.toggle-center {
            float: left;
            display: none;
        }
    }

