/* BLUE, BLACK, GRAY */
:root {
    --color-1: #23233B;
    --color-2: #2C4268;
    --color-3: #007BBA;
    --color-4: #00A9E2;
    --color-5: #7CCDF4;
    --color-6: #BCE3FA;
    --color-7: #9B9C9B;
    --color-8: #B2B0B0;
    --color-9: #C5C6C6;
    --color-10: #EBEBEB;
}