@import "../../default/default.css";

header {
    animation: fadeInFromTop 2s ease-out 0s 1;
    margin-top: 35px;
    width: 100%;
    height: 40px;
    line-height: 40px;
}

header nav ul {
    float: right;
}

header nav ul li {
    display: inline-block;
    list-style: none;
    padding: 0 16px;
}

header nav ul li a {
    font-family: sans-serif;
    font-size: 24px;
    font-size: min(1.8vw, 24px);
    color: var(--color-7, #9b9c9b);
}

header nav ul li a:hover {
    color: var(--color-6, #bce3fa);
    -webkit-transition: color 1s;
    -moz-transition:    color 1s;
    -ms-transition:     color 1s;
    -o-transition:      color 1s;
    transition:         color 1s;
    cursor: pointer;
}

li.nav-item {
    display: none;
}

header nav ul li a#resume-link {
    text-decoration: none;
    color: var(--color-5, #7ccdf4);
    border-radius: 10px;
    border: 2px solid var(--color-5, #7ccdf4);
    padding: 14px 30px;
}

header nav ul li a#resume-link:hover {
    transition: 0.8s;
    border-color: var(--color-4, #00a9e2);
    color: var(--color-4, #00a9e2);
}

a.menu-icon, div.btn-toggle-nav {
    display: none;
}

@media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 812px) {


        div.btn-toggle-nav {
            display: inline-block;
            color: var(--color-5, #7ccdf4);
            float: right;
            font-size: 40px;
            z-index: 3000;
        }

        div.btn-toggle-nav {
            display: inline-block;
            color: var(--color-5, #7ccdf4);
            float: right;
            font-size: 40px;
            z-index: 3000;
        }

        .circle {
            height: 48px;
            width: 48px;
            position:relative;
            z-index: 3000;
        }
        .line {
            position: absolute;
            height: 5px;
            width:95%;
            background:var(--color-5, #7CCDF4);
            border-radius:8px;
            transition: all cubic-bezier(0.26, 0.1, 0.27, 1.55) 0.35s;
        }
        .top {
            top:18%;
        }
        .middle {
            top:48%;
        }
        .bottom {
            top:78%;
        }
        .icon.close .top {
            transform:rotate(45deg);
            top:48%;
        }
        .icon.close .middle, .icon.close .bottom {
            transform:rotate(-45deg);
            top:48%;
        }

        .nav-main {
            display: none;
            visibility: hidden;
            position: absolute;
            background-color: var(--color-2, #007BBA);
            right: -75vw;
            top: 0;
            width: 75vw;
            height: 100%;
            z-index: 1000;
            transition: all 0.3s ease-in-out;
        }

        .nav-main ul {
            margin-top: 25vh;
            display: block;
            float: none;
            padding: 0;
        }

        .nav-main ul li.nav-item, li.active {
            width: 75%;
            margin: 10px 12px;
            display: block;
            text-align: center;
        }

        .nav-main ul li.nav-item a {
            color: var(--color-10, #EBEBEB);
            position: relative;
            width: 100%;
            display: inline-block;
            margin: 0 auto;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
        }

        a#resume-link {
            color: var(--color-10, #EBEBEB);
            position: relative;
            display: inline-block;
            margin: 0 auto;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
        }


    }
