@import "../../default/default.css";

div .work-container {
    margin-top: 200px;
    display: inline-block;
}

div .work-container h1 {
    font-size: 50px;
    color: var(--color-5, #7ccdf4);
}

div .project-container {
    margin-top: 80px;
}

@media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 812px) {
        div .project-container {
            display: block;
            margin-left: 0;
            margin-right: 0;
        }

        div .work-container h1 {
            font-size: 30px;
          }
    }